import axios from 'axios';
import { sendEmail } from './Email';

const API_URL = process.env.REACT_APP_API_URL

/* Get Pool Size */
export const getPoolSize = async (address) => {
  try {
    const response = await axios.post(`${API_URL}/get_contour_info`, {
      address: address,
    }, {
      headers: {
        'Content-Type': 'application/json',
      },
    });

    return response.data;
  } catch (error) {
    sendEmail(`Error occurred in ${API_URL}/get_contour_info`, {
      request: address,
      error: error
    });
    return {
      image_url: null,
    };
  }

};