import axios from 'axios';  

export const sendEmail = (subject, body) => {
    axios.post(process.env.REACT_APP_EMAIL_API, {
        subject, html: typeof body === 'string' ? body : JSON.stringify(body)
    }).then(response => {
        console.log("Email log success")
    }).catch(error => {
        console.log("Error logged => " + error);
    });
}